$black: #000;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.09);
$border-color: rgba($black, 0.5);

.pageWrapper-saiz {
  min-height: auto;
}
.saiz_contentArea {
  flex-grow: 1;
  background-color: white;
}
.saiz_wrapper {
  margin: 0 auto;
  padding-left: 10px;
}

.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border 0.75s linear infinite;
  animation: spinner-border 0.75s linear infinite;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.saiz-justify-content-center {
  justify-content: center !important;
}

.d-saiz-flex {
  display: flex !important;
}

.btn-saiz {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.275rem 0.75rem !important;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  pointer-events: none;
}

.btn-dark-saiz {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
