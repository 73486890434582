@import "overlays";
.saiz-header-light {
  color: #7a7a7a !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.saiz-shape-icon:hover path,
.saiz-shape-container:hover path,
.saiz-shape-container:hover rect {
  opacity: 0.5 !important;
  stroke: var(--saiz-widget-screen-two-color) !important;
}
.saiz-shape-container.selected path,
.saiz-shape-container.selected rect {
  opacity: 1 !important;
  fill: var(--saiz-widget-screen-two-background) !important;
  stroke: var(--saiz-widget-screen-two-color) !important;
}

.saiz-shape-container.selected .saiz-header-light {
  color: var(--saiz-widget-screen-two-color) !important;
}

.saiz-shape-container.selected .saiz-body-desc {
  color: var(--saiz-widget-screen-two-color) !important;
  opacity: 1 !important;
  // font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  // color: inherit !important;
  @media only screen and (min-width: 769px) {
    color: var(--saiz-widget-screen-two-color) !important;
  }
}

.saiz-modal-close-button {
  z-index: 99999;
}

.saiz-shape-container:hover .saiz-header-light {
  // font-weight: 400 !important;
  font-size: 12px !important;
  color: var(--saiz-widget-screen-two-color) !important;
  opacity: 0.8 !important;
}

.saiz-shape-container:not(.selected) .saiz-body-desc {
  opacity: 1 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: var(--saiz-widget-screen-two-color) !important;
  @media only screen and (min-width: 769px) {
    color: white !important;
  }
}

.selected-bundle {
  border-color: var(--saiz-widget-screen-two-color) !important;
}
body {
  color: var(--saiz-widget-font-color);
}
.bundle-size-box {
  min-width: 80px;
  min-height: 36px;
  padding: 12px 8px;
  margin-bottom: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
}
.screen-one-bundle-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 20px;
}
.saiz-shape-container:hover .saiz-body-desc {
  color: var(--saiz-widget-screen-two-color) !important;
  opacity: 0.8 !important;
  font-size: 12px !important;
}

.saiz-form {
  text-align: center !important;
}

.saiz-first-capital:first-letter {
  text-transform: uppercase !important;
}
.saiz-first-capital {
  font-size: var(--saiz-fontSizes-xs);
  color: var(--saiz-colors-brand-500);
  text-align: center;
  margin-top: var(--saiz-space-1);
  margin-bottom: 10px;
}
.saiz-tchibo-link-button:hover {
  color: red !important;
}

.summary-screen-nudging-container {
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 3px 16px);
  width: 250px;
  background: white;
  right: 30px;
  position: absolute;
  top: 40%;
  padding: 10px 20px;
  border-radius: 4px;

  .left-button {
    position: absolute;
    background-color: transparent;
    left: -20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .right-button {
    position: absolute;
    background-color: transparent;
    right: -20px;
    top: 50%;

    transform: translateY(-50%);
    cursor: pointer;
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  @media only screen and (max-width: 991px) {
    position: inherit;
    margin-top: 20px;
    margin-bottom: -20px;
  }
}
.summary-screen-nudging-p {
  text-align: center;
  line-height: 20px;
  font-size: 13px;
  max-height: 140px;
  overflow-y: scroll;
  scrollbar-width: none;
}
.saiz-fit-content {
  width: fit-content !important;
  margin: auto !important;
}
.ScreenThreeJW-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 0px 20px;
}
.saiz-caret-disabled {
  opacity: 0.2 !important;
  cursor: default !important;
}
.saiz-info-button-container {
  position: absolute;
  top: -30px;
  left: unset;
  display: inline-flex;
  right: unset;
  top: unset;
  margin-left: 10px;
  z-index: 99;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 25px;
  height: auto;
  .saiz-info-close-btn {
    position: absolute;
    display: none;
    top: 0px;
    right: 0px;
    cursor: pointer;
    color: white;
    @media only screen and (max-width: 991px) {
      display: block;
    }
  }
  ::before {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    background: black;
    top: 35px;
    left: 0px;
    rotate: 45deg;
    @media only screen and (max-width: 991px) {
      display: none;
    }
  }
}
.saiz-info-button-icon {
  cursor: pointer;
}
.saiz-info-button-wrapper {
  width: 300px;
  background: #000;
  border-radius: 4px;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  @media only screen and (max-width: 991px) {
    position: fixed;
    left: 5%;
    top: 10%;
    width: 90%;
  }

  p {
    color: #ffffff;
    font-weight: 400;
    text-align: start;
  }
  .saiz-info-button-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    svg {
      width: 32px;
      height: auto;
      min-width: 32px;
    }
    p {
      max-width: 250px;
    }
  }
}
.saiz-caret {
  opacity: 1 !important;
  cursor: pointer !important;
}

.css-* > *:not(style) ~ *:not(style) {
  margin-top: "" !important;
}
.summary-subheading {
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
  left: 0px;
  display: block;
  @media only screen and (max-width: 769px) {
    display: none;
  }
}
#female::before,
#male::before,
#female::after,
#male::after {
  display: none;
}
.saiz-shape-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

#saiz-widget-container {
  a {
    background-color: transparent !important;
    color: inherit !important;
    -webkit-text-decoration: inherit !important;
    text-decoration: inherit !important;
  }
}
.saiz-avatar-container {
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  font-size: medium;
  position: relative;
  width: var(--saiz-avatar-size);
  height: var(--saiz-avatar-size);
  --saiz-avatar-size: 3rem;
}
.saiz-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
}
.saiz-toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 0px 3px;
  gap: 16px;
}
.saiz-size-box {
  margin-left: 5px;
  margin-right: 4px;
  width: 60px;
  height: 26px;
  border: 1px solid;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.saiz-size-box-text {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600;
  user-select: none;
  color: #000;
}
.saiz-overlay {
  position: absolute;
  left: -130px;
  z-index: 11;
  right: auto;
  margin-top: 5px;
  @media only screen and (max-width: 991px) {
    left: -90px;
  }
}
.uppercase {
  text-transform: var(--saiz-widget-header-text-transform) !important;
}

.final_woman_img {
  width: 175px;
  margin-right: 30px;
  @media only screen and (max-width: 769px) {
    width: 0px;
  }
}
.saiz-main-button {
  width: 55px;
  height: 53px;
  width: 400px;
  max-width: 400px;
  margin-top: 5%;
  background: var(--saiz-colors-brand-1000);
  border-radius: 29px;
  color: var(--saiz-colors-white);
  @media only screen and (max-width: 769px) {
    width: 300px;
    max-width: 90vw;
  }
}
.arrow-lines-container {
  left: -180px;
  gap: 8px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 991px) {
    left: -80px;
  }
}

.saiz-main-button:hover {
  background: var(--saiz-colors-brand-700);
  color: var(--saiz-colors-white);
}
.saiz-main-button:focus {
  box-shadow: var(--saiz-shadows-outline);
}

.saiz-main-button-hidden {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}

.big-size-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  right: 115px;
  position: absolute;
  top: 15%;
  padding-bottom: 0px;
  align-self: center;
  @media only screen and (max-width: 991px) {
    right: auto;
    position: relative;
    top: 0%;
    padding-bottom: 20px;
  }
}
.box {
  position: absolute;
  left: -340px;
  top: 30px;
  color: var(--saiz-colors-brand-900);
  @media only screen and (max-width: 991px) {
    left: 180px;
    top: 0px;
  }
}

.saiz-screenFour-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  @media only screen and (max-width: 769px) {
    flex-direction: column-reverse;
  }
}
.saiz-encapculate {
  font-style: normal !important;
  font-size: 1rem !important;
  user-select: none;
  font-family: var(--saiz-widget-font-family-text);
  *,
  *::before,
  *::after {
    box-sizing: border-box !important;
    -webkit-text-size-adjust: 100% !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
    text-rendering: optimizeLegibility !important;
  }
  header {
    width: 100% !important;
  }
  p {
    margin: 0;
  }
  div:empty {
    display: block !important;
  }

  h2 {
    font-size: 1rem !important;
    font-family: var(--saiz-widget-font-family-header) !important;
  }
  .saiz_txt_gender {
    margin-bottom: 10px !important;
  }

  .saiz-input-age {
    border-radius: 34px !important;
    border-width: 2px !important;
    border-color: #7a7a7a !important;
    text-align: center !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    width: 85px !important;
    height: 33px !important;
    margin-bottom: 10px !important;
  }
  .saiz-input-age:focus-visible {
    box-shadow: none;
  }
  .saiz-input-age:hover {
    border-color: var(--saiz-colors-brand-500);
  }

  sub,
  sup {
    font-size: 75% !important;
    line-height: 0 !important;
    position: relative !important;
    vertical-align: baseline !important;
  }

  button,
  input {
    overflow: visible !important;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    padding: 0 !important;
    font-family: inherit !important;
    font-size: inherit !important;
    line-height: 1.15 !important;
  }

  button,
  select {
    text-transform: none !important;
  }
  button,
  [role="button"] {
    border-style: none !important;
    cursor: pointer !important;
    line-height: normal !important;
    text-align: center !important;
    font-size: 13px !important;
    padding: 2px 2px 2px 2px !important;
  }

  button:focus-visible {
    box-shadow: none !important;
    outline: none !important;
  }
  button:focus {
    box-shadow: none !important;
    outline: none !important;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block !important;
  }

  img {
    border-style: none !important;
  }

  .unzoomed {
    transform: scale(0.5) !important;
    width: 128px !important;
  }

  .zoomed {
    color: #b989c5 !important;
  }

  .arrowLeft {
    position: absolute !important;
    left: calc(50% - 90px) !important;
    top: 35% !important;
    z-index: 100 !important;
    display: block !important;
    @media only screen and (min-width: 769px) {
      display: none !important;
    }
  }

  .arrowRight {
    position: absolute !important;
    right: calc(50% - 90px) !important;
    top: 35% !important;
    z-index: 100 !important;
    display: block !important;
    @media only screen and (min-width: 769px) {
      display: none !important;
    }
  }
  .saiz-shapes-slider {
    display: flex;
    gap: 18px;
    margin-top: 5px;
    max-width: 100%;
    width: auto;
    @media only screen and (min-width: 769px) {
      display: none;
    }
  }

  .saiz-shapes-slider-desktop {
    display: none;
    gap: 18px;
    margin-top: 5px;
    max-width: 100%;
    width: auto;
    @media only screen and (min-width: 769px) {
      display: flex;
    }
  }

  input:not([type="range"]):not([type="color"]) {
    writing-mode: horizontal-tb !important;
  }
  input[type="text" i] {
    padding: 1px 2px;
  }
  input {
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    word-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    cursor: text;
    background-color: field;
    margin: 0em;
    padding: 1px 2px;
    border-width: 2px;
    border-style: inset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
  }
  .saiz-form {
    text-align: center !important;
  }
}
.saiz-nudge-button {
  user-select: none !important;
  color: black !important;
  z-index: var(--saiz-widget-zindex) !important;
  position: var(--saiz-widget-position) !important;
  right: 10px !important;
  top: var(--saiz-widget-nudgeTop) !important;
  cursor: pointer !important;
  border-radius: var(--saiz-radii-full) !important;
  display: inline-block !important;
  backdrop-filter: blur(19.226px) !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff !important;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background: var(--saiz-avatar-bg);
  border-color: var(--saiz-avatar-border-color);
  vertical-align: top;
  width: var(--saiz-avatar-size);
  height: var(--saiz-avatar-size);

  @media only screen and (max-width: 768px) {
    top: var(--saiz-widget-nudgeTop) !important;
  }
}

.SaizMainContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 480px;
  justify-content: center;
  margin: 0;
  min-height: 480px;
  padding-top: 3em;
  width: 100%;
  @media only screen and (max-width: 991px) {
    height: auto;
    justify-content: space-evenly;
    min-height: 60vh;
    padding: 0px;
  }
}
.saiz-screen-summary-button {
  height: 53px;
  width: 145px;
  bottom: 153px;
  background: var(--saiz-colors-brand-1000) !important;
  color: #ffffff;
  position: absolute;
  @media only screen and (max-width: 991px) {
    position: inherit;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
  }
}
.saiz-screen-summary-button:hover {
  background: var(--saiz-colors-brand-700);
  color: #ffffff;
}
.saiz-screen-summary-button:focus {
  background: var(--saiz-colors-brand-700);
}
#male-label,
#female-label {
  padding: unset !important;
  margin: unset !important;
  text-decoration: none;
}
#male,
#female {
  position: static !important;
  opacity: 1 !important;
}
#male-label::before,
#female-label::before {
  display: none !important;
}
#male-label::after,
#female-label::after {
  display: none !important;
}
#saiz-floating-button {
  font-family: var(--saiz-widget-font-family-text) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  user-select: none !important;
  color: black !important;
  z-index: var(--saiz-widget-zindex) !important;
  position: var(--saiz-widget-position) !important;
  height: 46px !important;
  right: -30px !important;
  top: var(--saiz-widget-buttonTop) !important;
  cursor: pointer !important;
  padding: 2px !important;
  border-radius: 46px !important;
  background: var(--saiz-widget-background-radiant) !important;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.12) !important;
  backdrop-filter: blur(19.226px) !important;
  & > div {
    font-family: var(--saiz-widget-font-family-text) !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 15px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center !important;
    background-color: white !important;
    padding: 0 30px 0 0 !important;
    height: 100% !important;
    border-radius: 46px !important;
  }

  .saiz-button-calculate,
  .saiz-button-calculate-mobile {
    margin-left: 20px !important;
    margin-right: 10px !important;
  }

  .saiz-best-size {
    margin-left: 9px !important;
    margin-right: 8px !important;
    font-family: var(--saiz-widget-font-family-text) !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    line-height: 13px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #ffffff !important;
    background-color: var(--saiz-widget-buttonColor) !important;
    width: auto !important;
    min-width: 22px !important;
    height: 30px !important;
    padding: 0 4px !important;
    border-radius: 40px !important;
    letter-spacing: 0.5px !important;
    box-sizing: content-box !important;
  }

  .saiz-best-size-text {
    padding-right: 10px !important;
  }

  .saiz-button-calculate-mobile {
    display: none !important;
  }

  &.slide-in {
    animation: slide-in 0s forwards ease-out 0s !important;
    -webkit-animation: slide-in 0s forwards ease-out 0s !important;
  }

  @keyframes slide-in {
    100% {
      transform: translateX(0%) !important;
    }
  }

  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(0%) !important;
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%) !important;
    }
    100% {
      transform: translateX(-100%) !important;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 40px !important;
    right: -46px !important;
    font-size: 11px !important;

    .saiz-button-calculate,
    .saiz-button-calculate-mobile {
      margin-left: 15px !important;
    }

    .saiz-button-calculate-mobile {
      display: inline-block !important;
      visibility: visible;
    }
    .saiz-button-calculate {
      display: none !important;
    }

    .saiz-best-size {
      margin-left: 5px !important;
      margin-right: 4px !important;
      width: auto !important;
      min-width: 16px !important;
      padding: 0 5px !important;
      height: 26px !important;
      font-size: 12px !important;
      line-height: 11px !important;
      box-sizing: content-box !important;
    }
    & > div {
      padding: 0 var(--saiz-widget-screen-768-div-padding) 0 0 !important;
    }
  }
}
#saiz-floating-button .saiz-fixed {
  position: fixed !important;
}

#saiz-floating-button .saiz-block-button {
  width: fit-content !important;
  position: absolute !important;
  right: -80px !important;
  top: -70px !important;
  z-index: 0 !important;
  .saiz-button-calculate {
    white-space: nowrap !important;
  }
}

.saiz-button {
  cursor: pointer !important;
  line-height: normal !important;
  text-align: center !important;
  font-size: 13px !important;
  padding: 2px 2px 2px 2px !important;
  border: 1px solid green;
}
.saiz-button:focus-visible {
  box-shadow: none !important;
}

.dashimage {
  width: 100%;
}

@media screen and (min-width: 30em) {
  .dashimage {
    width: 50%;
  }
}

@media screen and (min-width: 48em) {
  .dashimage {
    width: 25%;
  }
}
