.saiz-toggle-label {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.saiz-toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.saiz-toggle-span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d9d9d9;
  transition: 0.3s;
  border-radius: 20px;
}
.saiz-toggle-span:before {
  position: absolute;
  content: "";
  height: 14.6px;
  width: 14.6px;
  left: 3px;
  bottom: 2.1px;
  background-color: #fff;
  border-radius: 50%;
  transition: 0.3s;
}

.saiz-toggle-input:checked + .saiz-toggle-span {
  background-color: black;
}

.saiz-toggle-input:checked + .saiz-toggle-span:before {
  transform: translateX(18px);
}
