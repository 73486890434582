.saiz-fit-2,
.saiz-fit-3,
.saiz-fit-4,
.saiz-fit-5,
.saiz-fit-6,
.saiz-fit-7 {
  .main-stop {
    stop-color: #dd7171 !important; 
  }
  .alt-stop {
    stop-color: #dd7171 !important; 
  }
  .arrow {
    svg {
      fill: #d64646 !important; 
    }
  }
  .txt {
    color: #531313 !important; 
    font-size: 12px	!important;
  }

}

.islength:not(.saiz-fit-0) {
  .main-stop {
    stop-color: #f1d543 !important; 
  }
  .alt-stop {
    stop-color: #f1d543 !important; 
  }
}

.saiz-fit-1 {
  .main-stop {
    stop-color: #f1d543 !important; 
  }
  .alt-stop {
    stop-color: #f1d543 !important; 
  }
}


.saiz-fit-1 {
  .arrow {
    svg {
      fill: #f1d543 !important; 
    }
  }
  .txt {
    color: #524f16 !important; 
    font-size: 12px	!important;
  }
}

.islength:not(.saiz-fit-0) {
  .arrow {
    svg {
      fill: #f1d543 !important; 
    }
  }
  .txt {
    color: #524f16 !important; 
    font-size: 12px	!important;
  }
}
.saiz-fit-0,
.perfect {
  .arrow {
    svg {
      fill: #49c03f !important; 
    }
  }
  .txt {
    color: #247c1c !important;
    font-size: 12px	!important;
  }
  .main-stop {
    stop-color: #49c03f !important; 
  }
  .alt-stop {
    stop-color: #49c03f !important; 
  }
}
